/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import Link from "../link/link"

const Document = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    sx={{
      display: "inline",
      verticalAlign: "middle",
      ml: "0.5rem",
      width: "1.875rem",
      height: "1.875rem",
    }}
  >
    <polygon
      sx={{
        fill: "none",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      points="90.45 85.32 22.45 85.32 22.45 5.32 62.45 5.32 90.45 33.32 90.45 85.32"
    />
    <polyline
      sx={{
        fill: "none",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      points="62.45 5.32 62.45 33.32 90.45 33.32"
    />
  </svg>
)

const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    sx={{
      display: "inline",
      verticalAlign: "middle",
      ml: "0.5rem",
      width: "1.875rem",
      height: "1.875rem",
    }}
  >
    <polyline
      sx={{
        fill: "none",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      points="60.54 9.53 95.19 44.18 60.54 78.84"
    />
    <line
      sx={{
        fill: "none",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      x1="95.19"
      y1="44.18"
      x2="15.19"
      y2="44.18"
    />
  </svg>
)

const SVGIcon = ({ buttonIcon }) => {
  switch (buttonIcon) {
    case "arrow": {
      return <RightArrow />
    }
    case "document": {
      return <Document />
    }
    default:
      return null
  }
}

SVGIcon.propTypes = {
  buttonIcon: PropTypes.string,
}

SVGIcon.defaultProps = {
  buttonIcon: null,
}

const ButtonLink = ({
  buttonDestination,
  buttonStyle,
  buttonIcon,
  children,
}) => {
  return (
    <>
      <Link
        to={buttonDestination}
        className={buttonStyle}
        sx={{ variant: `buttons.${buttonStyle}`, mr: "2rem" }}
      >
        {children}
        <SVGIcon buttonIcon={buttonIcon} />
      </Link>
    </>
  )
}

ButtonLink.propTypes = {
  buttonDestination: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  buttonIcon: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ButtonLink.defaultProps = {
  buttonStyle: "primary",
  buttonIcon: null,
}

export default ButtonLink
