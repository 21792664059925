/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import { DialogOverlay, DialogContent } from "@reach/dialog"
// import "@reach/dialog/styles.css"
import VisuallyHidden from "@reach/visually-hidden"

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    sx={{
      display: "inline",
      verticalAlign: "middle",
      ml: "0.5rem",
      width:"1.875rem",
      height:"1.875rem"
    }}
  >
    <line
      sx={{
        fill: "none",
        // stroke: "#fff",
        stroke: "goreDarkGray",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      x1="10.03"
      y1="10.76"
      x2="90.03"
      y2="90.76"
    />
    <line
      sx={{
        fill: "none",
        // stroke: "#fff",
        stroke: "goreDarkGray",
        strokeMiterLimit: "10",
        strokeWidth: "5px",
      }}
      x1="10.03"
      y1="90.76"
      x2="90.03"
      y2="10.76"
    />
  </svg>
)

export default class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.onKeyDown = this.onKeyDown.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown)
  }

  onKeyDown({ key }) {
    const { open } = this.state
    if (open && key === "Escape") {
      this.hideModal()
    }
  }

  showModal() {
    this.setState({
      open: true,
    })
  }

  hideModal() {
    this.setState({
      open: false,
    })
  }

  render() {
    const { open } = this.state
    const close = () => this.hideModal()
    const { trigger, modalContent } = this.props

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          onClick={() => this.showModal()}
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              this.showModal()
            }
          }}
        >
          {trigger}
        </div>
        <DialogOverlay
          style={{
            background: "hsla(0, 0%, 0%, 0.33)",
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: "auto",
          }}
          isOpen={open}
          onDismiss={close}
        >
          <DialogContent
            style={{
              width: "100%",
              maxWidth: "414px",
              outline: "none",
            }}
            sx={{
              bg: "goreLighterGray",
              mt: ["3vh", "12vh"],
              mx: "auto",
              position: "relative",
            }}
          >
            <button
              onClick={() => this.hideModal()}
              sx={{
                border: 0,
                position: "absolute",
                right: "8px",
                top: "12px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <span aria-hidden>
                <Close />
              </span>
            </button>
            {modalContent}
          </DialogContent>
        </DialogOverlay>
      </>
    )
  }
}

Modal.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  modalContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
